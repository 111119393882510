:root {
  --main-grid-width-col-list: 294px;
  --main-grid-width-col-editor: 0.5;
  --main-grid-width-col-flowchart: 1fr;
  --main-grid-height-row-discuss: 0.3;
}

.main-wrapper {
  font-size: 14px;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow: initial;
  overflow-x: hidden;

  display: grid;
  grid-template-columns: [a] var(--main-grid-width-col-list) [b] calc((100% - var(--main-grid-width-col-list) - 32px) * var(--main-grid-width-col-editor)) [c] 32px [d] 1fr [e];
  // @TODO : Move this to a class, add and remove it only when needed
  will-change: grid-template-columns;
  grid-template-rows: [upper] 40px [one] 1fr [two] 32px [three] calc((100% - 40px - 32px) * var(--main-grid-height-row-discuss)) [lower];
  column-gap: 0px;
  row-gap: 0px;

  .small-state-manager {
    display: none;

    .selector {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      border: 0.5px solid grey;
      padding: 10px;
      margin: 0 5px;
      border-top-width: 0;
      border-radius: 0 0 10px 10px;

      &.active {
        background-color: lightgrey;
        color: black;
      }
    }

    @include viewport-xsmall {
      display: flex;
      justify-content: space-evenly;
    }
  }

  // .user-bar-wrapper {
  //   grid-column-start: a;
  //   grid-column-end: e;
  //   grid-row-start: upper;
  //   grid-row-end: one;
  //   display: grid;
  //   grid-template-columns: 294px 1fr auto;
  //   border-bottom: 1px solid black;
  // }

  .list-column-wrapper {
    @include debug {
      background-color: rgba(255, 0, 0, 0.2);
    }

    grid-column-start: a;
    grid-column-end: b;
    grid-row-start: one;
    grid-row-end: lower;
  }

  .richtext-column-wrapper {
    @include debug {
      background-color: rgba(0, 255, 0, 0.2);
    }

    grid-column-start: b;
    grid-column-end: c;
    grid-row-start: one;
    grid-row-end: two;
  }

  .slider {
    @include debug {
      background-color: palegoldenrod;
    }
    background-color: #ededed;
    cursor: grab;
    $standard-border: 0.5px solid grey;
    overflow: hidden;

    &-vertical {
      grid-column-start: c;
      grid-column-end: d;
      grid-row-start: one;
      grid-row-end: two;
      border-left: $standard-border;
      border-right: $standard-border;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-horizontal {
      grid-column-start: b;
      grid-column-end: e;
      grid-row-start: two;
      grid-row-end: three;
      border-top: $standard-border;
      border-bottom: $standard-border;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .flowchart-column-wrapper {
    @include debug {
      background-color: rgba(0, 0, 255, 0.2);
    }

    grid-column-start: d;
    grid-column-end: e;
    grid-row-start: one;
    grid-row-end: two;
  }

  .discussion-bar-wrapper {
    @include debug {
      background-color: rgba(0, 0, 0, 0.1);
    }

    grid-column-start: b;
    grid-column-end: e;
    grid-row-start: three;
    grid-row-end: lower;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include viewport-xsmall {
    grid-template-rows: [upper] 40px [one] 40px [two] 1fr [lower];
    grid-template-columns: [a] 1fr [b];

    // .user-bar-wrapper {
    //   grid-column-start: a;
    //   grid-column-end: b;
    //   grid-row-start: upper;
    //   grid-row-end: one;
    //   display: grid;
    //   grid-template-columns: auto 1fr auto;
    //   border-bottom: 1px solid black;
    // }

    .small-state-manager {
      border: 1px solid blue;
      grid-column-start: a;
      grid-column-end: b;
      grid-row-start: one;
      grid-row-end: two;
    }

    .slider {
      display: none;
    }

    .list-column-wrapper,
    .richtext-column-wrapper,
    .flowchart-column-wrapper,
    .discussion-bar-wrapper {
      grid-column-start: a;
      grid-column-end: b;
      grid-row-start: two;
      grid-row-end: lower;

      &.small-hide-view {
        display: none;

        * {
          display: none !important;
        }
      }
    }
  }

  @import './views/main/user-bar';
  @import './views/main/list-column';
  @import './views/main/rich-text-column';
  @import './views/main/flow-chart-column';
  @import './views/main/discussion-bar';
  @import './views/main/grid-overlay';
  @import './views/main/loading-overlay';
}