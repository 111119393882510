body,
html,
#root {
  height: 100%;
  width: 100%;
}

.App {
  height: 100%;
  width: 100%;
}