.sign-in-form {
    display: grid;
    grid-template-columns: [one] auto [two];
    grid-row-gap: 10px;
    border: 0.5px solid grey;
    text-align: left;
    padding: 20px;
    border-radius: 20px;

    .button {
        width: fit-content;
    }

    .prompt {
        margin-top: 0;
        color: red;
        font-size: 0.7em;
    }

    &.disabled {
      pointer-events: none;
    }
}