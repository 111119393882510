.grid-overlay {
  grid-column-start: b;
  grid-column-end: e;
  grid-row-start: one;
  grid-row-end: two;
  display: grid;
  grid-template-rows: [a] 1fr [b];
  grid-template-columns: [a] 16px [b] repeat(10, auto) [c] 16px [d];
  pointer-events: none;

  &.hidden {
    display: none;
  }

  &.grid-horizontal {
    grid-row-start: one;
    grid-row-end: lower;
    grid-template-columns: [a] 1fr [c];
    grid-template-rows: [a] 16px [b] repeat(10, auto) [c] 16px [d];

    .col {
      border-top: 0.5px solid red;
      border-left: none;
    }
  }

  .col {
    border-left: 0.5px solid red;
    box-sizing: border-box;

    &:first-child  {
      border: none;
    }
  }
}