.help-wrapper {
  width: 100%;
  height: 100%;
  
  ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
  }

  .user-bar-wrapper {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: top;
    grid-row-end: one;
    display: grid;
    grid-template-columns: 294px 1fr auto;
    border-bottom: 0.5px solid grey;


    div,
    a {
      display: flex;
      align-items: center;

      &.flow-name {
        justify-content: center;
      }
    }

    
  }

  .inner-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}