.search-input-wrapper {
    position: relative;

    // @todo - messy start
    &:focus-within {
        .searchbar-results {
            display: block;
        }
    }
    // @todo - messy end

    .searchbar-results {
        padding: 0;
        margin: 0;
        list-style: none;
        color: black;
        text-align: left;
        font-size: 1em;
        margin: 0 30px;
        padding: 7px;
        border: 0.5px solid grey;
        border-top: 0;
        border-radius: 0 0 8px 8px;
        position: absolute;
        width: -webkit-fill-available; //@TODO check this with caniuse
        display: none; // @todo - messy
    }
    
    &.style-large {
        .search-bar {
            display: flex;
            padding: 5px;
            border: 0.5px solid grey;
            border-radius: 12px;
            width: 550px;
        }
    
        .search-input {
            border: 0;
            padding: 0;
            margin: 0;
            background-color: inherit;
            width: 100%;
    
            &:focus-visible {
                outline: 0;
            }
        }
    }
}
