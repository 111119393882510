.admin-wrapper {
  display: grid;
  grid-template-columns: [a] 1fr [b] 1fr [c];
  grid-template-rows: auto;
  height: 100%;
  width: 100%;

  .form {
    display: flex;
    flex-direction: column;
    padding: 20px;

    * {
      margin-top: 10px;
    }
  }
}