.search-wrapper {
  font-size: 14px;
  min-height: 100vh;
  width: 100vw;

  .user-bar-wrapper {
    height: 40px;
    grid-row-start: top;
    grid-row-end: one;
    display: grid;
    grid-template-columns: 294px 1fr auto;
    border-bottom: 0.5px solid grey;


    div,
    a {
      display: flex;
      align-items: center;

      &.flow-name {
        justify-content: center;
      }
    }

    .user-logo {
      margin-right: 9px;
    }
  }

  .logo-search-wrapper {
    margin-left: 5px;
  }

  .search-input-wrapper {
    padding: 75px 0 20px 0;

    .search-bar {
      margin: 0 auto;
      display: none;
    }
  }

  .search-results {
    list-style-type: none;
    width: 550px;
    margin: 60px auto 0;
    text-align: left;
    padding: 0 38px;

    .list-item {
      display: flex;
      padding: 20px 10px;
      margin-bottom: 20px;
      align-items: center;

      button {
        margin-left: 15px;
      }
    }
  }
}