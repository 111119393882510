.flowchart-column-wrapper {
    overflow: hidden;

    .expanded-content {
        height: 100%;
        width: 100%;
    }
    
    .flowchart-wrapper {     
        height: 100%;
        width: 100%;
    }
}