// Shared Styles for Grid on Admin pages

.admin-form {
    .form-row {
        display: grid;
        grid-template-columns: 45% 1fr;
        grid-row-gap: 15px;
        grid-column-gap: 5px;
        margin-bottom: 40px;
    }

    .fixed-data,
    .editable-data {
      border: 0.5px solid grey;
      font-size: 1em;
      padding: 0.2em;
      grid-column-start: 2;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .fixed-data {
      border-color: white;
      font-style: italic;
    }

    .editable-data {
      color: blue;
    }

    .grid-left {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 0.3em;
      padding: 1px;
      text-align: right;
    }

    .grid-right {
      text-align: left;
    }

    .prompt {
      font-size: 0.7rem;
      color: grey;
    }
  }