.user-bar-wrapper {
  grid-column-start: a;
  grid-column-end: e;
  grid-row-start: upper;
  grid-row-end: one;
  display: grid;
  grid-template-columns: 294px 1fr auto;
  border-bottom: 0.5px solid grey;
  // add drop shadow for border weight

  @include viewport-xsmall {
    grid-column-start: a;
    grid-column-end: b;
    grid-row-start: upper;
    grid-row-end: one;
    display: grid;
    grid-template-columns: auto 1fr auto;
    // border-bottom: 0.5px solid black;
  }

  .user-logo {
    margin-right: 9px;
  }

  .logo-search-wrapper {
    padding-left: 5px;
  }

  div,
  a {
    display: flex;
    align-items: center;

    &.flow-name {
      justify-content: center;
    }
  }
}