.list-column-wrapper {
    overflow-y: scroll;
    border-right: 0.5px solid grey;
    background-color: #ededed;
    display: inline-table;

    .list {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;

        .item {
            // border: 1px solid black;
            padding: 20px;
            position: relative;
            margin: 5px 25px 5px 10px;
            display: flex;
            /* fallback if grab cursor is unsupported */
            cursor: move;
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
            // transition: background-color 2s;

            &:hover {
                background-color: #dedede;
            }

            &.active {
                background-color: #b6b3b3;
                box-shadow: none;
            }
        }

        li:nth-child(1) .item {
            margin-top: 10px;
        }

        .link {
            cursor: pointer;
            margin-bottom: 16px;

            &:hover {
                text-decoration: underline;
            }
        }

        .new-item {
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                position: relative;
                padding: 3px;
            }
        }

        .over {
            border-style: dashed;
        }

        .convert-to-placeholder {
            background-color: grey;
            color: grey;
            border-color: grey;
            // pointer-events: none;

            .icons {
                pointer-events: none;
            }
        }
    }

    .parent-item {
        border: 0.5px solid grey;
        padding: 20px;
        position: relative;
        margin: 5px 10px 0 10px;
        display: grid;
        grid-template-columns: 20px 1fr auto;
        grid-template-rows: 1fr;
        padding: 20px;
        padding-left: 0px;

        .history-icons {
            display: flex;
            align-items: center;
            padding-left: 5px;

            &:hover {

                background-color: #ccc7c7;
                border-radius: 30%;
            }
        }

        .item-marker {
            width: 15px;
            display: none;
        }

        .title-wrapper {
            display: flex;
        }

        .title {
            font-weight: bold;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        &:hover {
            background-color: #dedede;
        }

        &.active {
            background-color: #b6b3b3;
        }

    }

    // NOT USED ANY MORE
    // .item-marker {
    //     width: 5px;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     bottom: 0;

    //     &.active {
    //         background-color: blue;
    //         background-color: #b6b3b3;
    //     }
    // }

    .icons {
        position: absolute;
        bottom: 0;
        right: 3px;

        svg {
            // margin-right: 3px;
            height: 30px;
            width: 30px;
            padding: 4px;

            &:hover {

                background-color: #ccc7c7;
                border-radius: 40%;
            }
        }
    }
}