.richtext-column-wrapper {
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;

    .expanded-content {
        width: 100%;

        @include debug {
            background-color: blue;
        }

        iframe {
            border: none;
        }

        &.disabled {
            iframe {
                pointer-events: none;
            }
        }
    }
}