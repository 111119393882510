.page-overlay {
    &.loading, &.error {
        display: none;
        opacity: 0.1;
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 1000;
        pointer-events: none;
    }
    
    &.loading {
        background-color: white;
    }

    &.error {
        background-color: #ffd4d2;
    }
}