@import './styles/core/settings';

body {
  color: black;
}

.App {
  // @include viewport-large {
  //   color: red;
  // }
  // @include viewport-medium {
  //   color: blue;
  // }
  // @include viewport-small {
  //   color: yellow;
  // }
  // @include viewport-xlarge {
  //   color: purple;
  // }
  // @include viewport-xsmall {
  //   color: green;
  // }
  text-align: center;
  background-color: #e3e3e3;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
//   font-size: calc(10px + 2vmin);
}

nav.user-prompt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &.alert {
    background: #ff6606;
    color: #fff;
  }

  &.hidden {
    display: none;
  }
}

@import './styles/shared/searchBar';
@import './styles/shared/typography';
@import './styles/shared/base';

@import './styles/home';
@import './styles/main';
@import './styles/organisation';
@import './styles/user';
@import './styles/search';
@import './styles/signIn';
@import './styles/help';
@import './styles/admin';


// @TODO - remove this with the flowchart page:
.App .flowchart-wrapper {
  height: 100vh;
  width: 100vh;
  overflow: hidden;
}
// @TODO end