.home-wrapper {
  font-size: 14px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: [a] 88px [b] auto [c];
  grid-column-gap: 10px;
  grid-row-gap: 20px;


  .logo {
    width: 88px;
    height: 88px;

    &-container {
      display: flex;
      grid-column-gap: 10px;
      justify-content: center;
    }
  }

  .login-form {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: [a] auto [b] auto [c];
    grid-column-gap: 10px;
    grid-row-gap: 20px;
  }
}