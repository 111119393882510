.organisation-wrapper {
  width: 100%;
  padding: 100px 12%;

  @import './shared/adminForm';

  .generic-list {
    display: flex;
    flex-wrap: wrap;

    .list-item {
      border: 0.5px solid grey;
      border-radius: 12px;
      margin-right: 12px;
      margin-bottom: 12px;
      width: 100px;
      min-height: 150px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      overflow: hidden;

      .image {
        font-size: 70px;
        margin: 0 auto;
      }

      .user-id {
        width: 100%;
        white-space: break-spaces;
        text-align: center;
        margin: 0;
      }
    }
  }
}