.discussion-bar-wrapper {

    .discussion-list {
        list-style: none;
        padding: 0 44px;

        .item {
            // display: grid;
            grid-template-columns: 0px auto;
            margin-top: 20px;
            padding: 20px;

            .right {
                display: grid;
                grid-template-columns: [left] auto [right];
                grid-template-rows: [name] auto [comment] auto [votes] auto [bottom];
                margin-left: 20px;

                * {
                    padding: 0;
                    margin: 0;
                }

                .votes {
                    display: flex;
                    span {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }

            .comment {
                white-space: break-spaces;
                text-align: left;
            }

            .name {
                text-align: left;
              }
        }

        .new-item {
            border: 0.5px solid grey;
            border-radius: 6px;

            .right {
              border-bottom-width: 0;
            }

            .text-area-wrapper {
                display: grid;
                width: calc(100% - 20px);
                margin-right: 20px;
                padding: 0;
                grid-template-rows: 1fr;
                grid-template-columns:  1fr;
            }

            .pseudo-text-area {
                // hidden element with dupllicate content provides the resize of the text area
                grid-area: 1 / 1 / 2 / 2;
                border: 0.5px solid grey;
                border-radius: 3px;
                padding: 0.5rem;
                font: inherit;
                white-space: break-spaces;
                visibility: hidden;
            }

            .text-area {
                grid-area: 1 / 1 / 2 / 2;
                resize: none;
                overflow: hidden;
                border: 0.5px solid grey;
                border-radius: 3px;
                padding: 0.5rem;
                font: inherit;
            }
        }
    }

    .item {
        &.show-discussion-layer {
            >.nested-list {
                height: auto;
            }
        }
    }

    .nested-list {
        padding:0;
        padding-left: 30px;
        grid-column-start: span 2;
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease;
        list-style: none;

        .item {
            &:first-child {
                margin-top: 20px;
            }
        }
    }
}