.user-wrapper {
  width: 100%;
  height: 100%;
  // padding: 100px 12%;

  .user-bar-wrapper {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: top;
    grid-row-end: one;
    display: grid;
    grid-template-columns: 294px 1fr auto;
    border-bottom: 0.5px solid grey;


    div,
    a {
      display: flex;
      align-items: center;

      &.flow-name {
        justify-content: center;
      }
    }

  }

  .user-logo {
    margin-right: 9px;
  }
  
  .inner-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }

  @import './shared/adminForm';

  .user-image {
    height: 100px;
    width: 100px;
  }
}