/*
Media Querys for all viewports

Useage:

@include viewport-small {
  background-color: red;
}

reference: https://css-tricks.com/approaches-media-queries-sass/
reference: https://mui.com/material-ui/customization/breakpoints/
*/

$xs-width: 0px;
$sm-width: 600px;
$md-width: 900px;
$lg-width: 1200px;
$xl-width: 1536px;

@mixin viewport-xsmall {
  @media (max-width: #{$sm-width - 1px}) {
    @content;
  }
}

@mixin viewport-small {
  @media (max-width: #{$md-width - 1px}) {
    @content;
  }
}

@mixin viewport-medium {
  @media (max-width: #{$lg-width - 1px}) {
    @content;
  }
}

@mixin viewport-large {
  @media (max-width: #{$xl-width - 1px}) {
    @content;
  }
}

@mixin viewport-xlarge {
  @media (min-width: $xl-width) {
    @content;
  }
}

/*
Media Querys for -only viewports
*/

@mixin viewport-xsmall-only {
  @media (min-width: #{$xs-width}) and (max-width: #{$sm-width - 1px}) {
    @content;
  }
}

@mixin viewport-small-only {
  @media (min-width: #{$sm-width}) and (max-width: #{$md-width - 1px}) {
    @content;
  }
}

@mixin viewport-medium-only {
  @media (min-width: #{$md-width}) and (max-width: #{$lg-width - 1px}) {
    @content;
  }
}

@mixin viewport-large-only {
  @media (min-width: #{$lg-width}) and (max-width: #{$xl-width - 1px}) {
    @content;
  }
}

@mixin viewport-xlarge-only {
  @media (min-width: $xl-width) {
    @content;
  }
}

/*
HTML class decorators 
*/

@mixin debug {
  @at-root html.debug & {
    @content;
  }
}